import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import server from '../server/server';
import Cookies from 'js-cookie'
import { clearData } from '../stores/userStore';

const SessionValidator = ({ children }) => {
    const history = useHistory();
    const location = useLocation();
    const [isValidating, setIsValidating] = useState(true);

    const validateSession = async () => {
        const token = Cookies.get('token');
        if (!token) {
            setIsValidating(false);
            history.push('/');
            return;
        }

        try {
            const response = await server({
                method: "get",
                url: "/users/verify-session",
                headers: {
                    pakAgusToken: token,
                },
            });

            if (!response.data.isValid) {
                throw new Error(response.data.message || 'Session is invalid');
            }
            setIsValidating(false);
        } catch (error) {
            console.error("Session validation failed:", error.message);
            clearData()
            history.push('/');
        }
    };

    useEffect(() => {
        validateSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    if (isValidating) {
        return <div>Validating session...</div>;
    }

    return <>{children}</>;
};

export default SessionValidator;