import { atom } from "recoil";
import Cookies from 'js-cookie'

import server from "../server/server";

const token = Cookies.get('token')

export const loginFromStore = (data, cb) => {
  server({
    method: "post",
    url: "/users/login",
    data,
  })
    .then(({ data }) => {
      if (data.status) {
        cb(data, null);
      } else {
        cb(null, { message: data.message })
      }
    })
    .catch((err) => {
      cb(null, { message: err });
    });
};

export const meDataState = atom({
  key: "meDataState",
  default: [],
});

// all siswa
export const allSiswaState = atom({
  key: "allSiswaState",
  default: [],
});
// fetchAllSiswa
export const fetchAllSiswa = (cb) => {
  server({
    method: "get",
    url: "/users/siswa",
    headers: {
      pakAgusToken: token,
    },
  })
    .then(({ data }) => {
      if (data) {
        cb(data, null);
      }
    })
    .catch((err) => {
      if (err) {
        console.log(err)
        cb(null, err);
      }
    });
};

export const fetchSiswaById = (id, cb) => {
  server({
    method: "get",
    url: `/users/${id}`,
    headers: {
      pakAgusToken: token,
    },
  })
    .then(({ data }) => {
      if (data) {
        cb(data, null);
      }
    })
    .catch((err) => {
      if (err) {
        cb(null, err);
      }
    });
};

// update data siswa, username, password, batch
export const updateDataSiswa = (payload, cb) => {
  server({
    method: "put",
    url: `/users/${payload.id}`,
    headers: {
      pakAgusToken: token,
    },
    data: payload,
  })
    .then(({ data }) => {
      if (data) {
        cb(data, null);
      }
    })
    .catch((err) => {
      if (err) {
        cb(null, err.response);
      }
    });
};

// update siswa
export const updateSiswa = (data, cb) => {
  if (data.sedang_ujian === "false") {
    data.sedang_ujian = "true";
  } else if (data.sedang_ujian === "true") {
    data.sedang_ujian = "false";
  }

  server({
    method: "put",
    url: `/users/${data.id}`,
    headers: {
      pakAgusToken: token,
    },
    data: { sedang_ujian: data.sedang_ujian },
  })
    .then(({ data }) => {
      if (data) {
        cb(data, null);
      }
    })
    .catch((err) => {
      if (err) {
        cb(null, err.response);
      }
    });
};

// tambah siswa
export const tambahSiswa = (data, cb) => {
  server({
    method: "post",
    url: `/users`,
    headers: {
      pakAgusToken: token,
    },
    data,
  })
    .then(({ data }) => {
      if (data) {
        cb(data, null);
      }
    })
    .catch((err) => {
      if (err) {
        cb(null, err.response);
      }
    });
};

// delete siswa
export const deleteSiswaFromStore = (data, cb) => {
  server({
    method: "post",
    url: `/users/delete-siswa/many`,
    headers: {
      pakAgusToken: token,
    },
    data,
  })
    .then(({ data }) => {
      if (data) {
        cb(data, null);
      }
    })
    .catch((err) => {
      if (err) {
        cb(null, err.response);
      }
    });
};

// update banyak siswa
export const updateBanyakSiswa = (data, cb) => {
  server({
    method: "put",
    url: `/users/update-siswa/many`,
    headers: {
      pakAgusToken: token,
    },
    data,
  })
    .then(({ data }) => {
      if (data) {
        cb(data, null);
      }
    })
    .catch((err) => {
      if (err) {
        cb(null, err.response);
      }
    });
};

// unblock siswa
export const unblockSiswa = (data, cb) => {
  server({
    method: "put",
    url: `/users/unblock/${data.id}`,
    headers: {
      pakAgusToken: token,
    },
    data: { status: data.status },
  })
    .then(({ data }) => {
      if (data) {
        cb(data, null);
      }
    })
    .catch((err) => {
      if (err) {
        cb(null, err.response);
      }
    });
};

// edit course access
export const editCourseAccess = (data, cb) => {
  server({
    method: "put",
    url: `/users/courseAccess/${data.id}`,
    headers: {
      pakAgusToken: token,
    },
    data: { psikologi: data.psikologi, akademik: data.akademik },
  })
    .then(({ data }) => {
      if (data) {
        cb(data, null);
      }
    })
    .catch((err) => {
      if (err) {
        cb(null, err.response);
      }
    });
};

export const clearData = () => {
  // localStorage.clear()
  Cookies.remove('meData')
  Cookies.remove('token')
}

export const getMeData = () => {
  const meData = Cookies.get("meData")
    ? JSON.parse(Cookies.get("meData"))
    : null;

  return meData
}