import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getMeData } from "../stores/userStore";

export default function Error404() {
    const history = useHistory();
    const meData = getMeData()

    function handleClick() {
        if (meData.role === "admin" || meData.role === "super-admin") {
            history.push("/dashboard");
        } else {
            history.push("/dashboard-siswa");
        }
    }

    return (
        <div className='h-screen bg-blue-500 grid place-items-center text-white'>
            <div className="flex flex-col items-center">
                <p className="text-4xl">
                    404 Not found
                </p>
                <button className="btn bg-white text-blue-500 border-none mt-8 hover:bg-white hover:text-blue-500"
                    onClick={handleClick}
                >Kembali ke Home</button>
            </div>

        </div>
    )
}